var m = require("mithril");
// var Provider = require('mithril-redux')
// import {Provider} from 'mithril-redux';
var EvGService = require("./services/EvGService");
var AppState = require("./services/AppState");
var Auth = require("./services/Auth");
var VersionCheck = require("./services/VersionCheck");


var DatePicker = require('mithril-datepicker');

var Splash = require("./views/Splash");
var Menu = require("./views/Menu");
var VersionPage = require("./views/VersionPage");
var SettingsPage = require("./views/SettingsPage");
var ActivityResourceList = require("./views/ActivityResourceList");
var ActivityResourcePage = require("./views/ActivityResourcePage");
var SchoollogisticPage = require("./views/SchoollogisticPage");
import {MyCrewScheduleList, CrewScheduleList} from "./views/CrewScheduleList";
var ChildScheduleList = require("./views/ChildScheduleList");
var ChildSchoolList = require("./views/ChildSchoolList");
var ChildActivityList = require("./views/ChildActivityList");
var MentorChildList = require("./views/MentorChildList");
var ChildPage = require("./views/ChildPage");
var CrewPage = require("./views/CrewPage");
var BlogMsgCreate = require("./views/BlogMsgCreate");
var BlogMsgList = require("./views/BlogMsgList");
var ChildNoteCreate = require("./views/ChildNoteCreate");
var ChildNoteList = require("./views/ChildNoteList");
var ChildObservationCreate = require("./views/ChildObservationCreate");
var ChildObservationList = require("./views/ChildObservationList");
var MyChildList = require("./views/MyChildList");
var MyChildPage = require("./views/MyChildPage");
var ChildrenNoteList = require("./views/ChildrenNoteList"); // es-6 class
var DaytypeNoteList = require("./views/DaytypeNoteList"); // es-6 class
var DaytypeNoteCreate = require("./views/DaytypeNoteCreate"); // es 6 class
var EvGToday = require("./views/EvGToday");
var EvGTodayFilter = require("./views/EvGTodayFilter");


var MyLastminutePage = require("./views/MyLastMinutePage");
var CaretakerChangerequestPage = require("./views/CaretakerChangerequestPage");
var CaretakerChangerequestMessage = require("./views/CaretakerChangerequestMessage");
var LastMinuteOfferPage = require("./views/LastMinuteOfferPage");
var LastMinuteClaimPage = require("./views/LastMinuteClaimPage");

var Login = require("./views/Login");
var Logout = require("./views/Logout");

var NetworkStatus = require("./services/NetworkStatus");


var myOptions = {
    weekday: 'short',
    day: '2-digit',
    month: 'short',
    year: 'numeric'
}

DatePicker.localize({
  weekStart: 1, // Maandag
  locale: 'nl',
  prevNextTitles: ['1 Mnd', '1 Jr', '10 Jr'],
  formatOptions: myOptions
})

var root = document.getElementById("evg_app");

class startApp {
    constructor(vnode) {
    }

    view(vnode) {
        m.route(root, "/menu",
              { "/login": Login,
                "/logout": Logout,

                "/splash": Splash,
                "/menu": Menu,

                "/versionupdate": VersionPage,
                "/evg-settings": SettingsPage,

                //routing voor Crew
                "/evgtoday": EvGToday,
                "/evgtoday-filter": EvGTodayFilter,
                "/activityresourcelist": ActivityResourceList,
                "/schoollogistic": SchoollogisticPage,
                "/activityresource/:Id": ActivityResourcePage,
                "/crew-my-schedulelist": MyCrewScheduleList,
                "/crewschedulelist": CrewScheduleList,
                "/crew/:Id": CrewPage,
                "/crew/:Id/eilandday/:Date": CrewPage,
                "/childschedulelist": ChildScheduleList, // Menu: "Groep Rooster"
                "/childschoollist": ChildSchoolList,
                "/childactivitylist": ChildActivityList,
                "/mymentorchildlist": MentorChildList,

                "/child/:Id": ChildPage,
                "/child/:Id/mentorinfo": ChildPage,
                "/child/:Id/eilandday/:IdEvgDay": ChildPage, // id van ParentDay, maar datumfilter (:Date) handiger?
                "/child/:Id/blogmsgwrite": BlogMsgCreate,
                "/child/:Id/blogmsglist": BlogMsgList,
                "/child/:Id/activitylist": ActivityResourceList,
                "/child/:Id/notewrite": ChildNoteCreate,
                "/child/:Id/notelist": ChildNoteList,
                "/child/:Id/observationwrite": ChildObservationCreate,
                "/child/:Id/observationlist": ChildObservationList,

                "/childnotelist": ChildrenNoteList,
                "/childnotelist/child/:Id/notewrite": ChildNoteCreate,

                "/daytypenotelist": DaytypeNoteList,
                "/daytypenote/:Date/:Daytype/create": DaytypeNoteCreate,

                "/blogmsgwrite": BlogMsgCreate,


                // routing voor ouders
                "/mychild": MyChildList,
                "/mychild/:Id": MyChildPage,
                "/mylastminute": MyLastminutePage,
                "/lastminuteoffer/:Id": LastMinuteOfferPage,
                "/lastminuteclaim/:Id": LastMinuteClaimPage,
                "/mychangerequest": CaretakerChangerequestPage,
                "/mychangerequest/:Id": CaretakerChangerequestMessage, // CaretakerChangerequest,

              }
        );
    }
}

if(window.cordova) {

  document.addEventListener('deviceready',
                            function() {
                               try {
                                   VersionCheck.getVersionNumber();
                                   // console.log("cordova: "+cordova);
                                   cordova.getAppVersion.getVersionNumber((version) => {
                                                                               console.log("index.js getAppVersion: "+version);
                                                                               // VersionCheck.setVersionNumber(version);
                                                                          }
                                                                         );
                               } catch(error) {
                                   console.error(error);
                               }

                               try {
                                   console.log(navigator.device.capture);
                               } catch(error) {
                                   console.error(error);
                               }

                               console.log("navigator.onLine: "+navigator.onLine)
                               NetworkStatus.checkConnection();

                               let theApp = new startApp();
                               theApp.view();
                            },
                            false);

  document.addEventListener("offline", NetworkStatus.handleOffline, false);
  document.addEventListener("online", NetworkStatus.handleOnline, false);


} else {
  console.log("Geen Cordova")
  let theApp = new startApp();
  theApp.view();
}
