var m = require("mithril")
var EvGService = require("../services/EvGService")
var AppState = require("../services/AppState")
/* Display Activities and planned activities for given child on given day */
var ChildActivityResource = require("../models/ChildActivityResource");

var TimeAsBarLabels = require('../components/TimeAsBarLabels')
var TimeAsBar = require("./TimeAsBar")

class ChildEilanddaySchedule {

  view(vnode) {
    const schedule_list = vnode.attrs.schedule_list;
    let rendered_schedule_list = [];
    schedule_list.map((childeilandday) => {
        // const _content = childeilandday.day+" "+childeilandday.delivery_time_short+"-"+childeilandday.pickup_time_short;
        const _content = [m('.full-name',childeilandday.day),
                          m('.time', m(TimeAsBar, {StartTime: childeilandday.delivery_time_short,
                                                   EndTime: childeilandday.pickup_time_short}))
                         ];
        rendered_schedule_list.push(m('.evg-flow-list-item', _content))
        })

    if (rendered_schedule_list.length == 0) {
        rendered_schedule_list.push( m('.evg-flow-list-item', "Geen Rooster Data"));
	}
    return m('.evg-list',
                    [m('.evg-flow-list-item',
                       m('.time-labels', m(TimeAsBarLabels, {StartTime: 7, EndTime:18}))),
                     rendered_schedule_list])
  }

}

module.exports = ChildEilanddaySchedule
