var m = require("mithril")
var AppState = require("../services/AppState");
var Auth = require("../services/Auth");

var EilandNote = require("./EilandNote")

class DaytypeNote extends EilandNote {

    constructor(vnode) {
      super();
      this.list = [];
      this.count = 0;
      this.marked_seen_count = "-";
      this.marked_unseen_count = "-";

      this._receiveList = this._receiveList.bind(this);
      this.loadList = this.loadList.bind(this);

      this.write_daytype_note = this.write_daytype_note.bind(this);
      this._write_daytype_note_result = this._write_daytype_note_result.bind(this);
      //this. = this..bind(this);

    }

    _receiveList(result) {
        this.list = result.results;
        this.count = result.length;
        this.marked_unseen_count = 0;
        console.log("this.list: "+this.list);
        this.list.forEach((note) => {if(!note.marked_as_seen){this.marked_unseen_count++}});
        // console.log("this.marked_unseen_count: "+this.marked_unseen_count);
    }

    loadList() {
        let request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/daytypenote/?date="+AppState.get_filter_date()
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveList)
                .catch((e) => {
                    console.log("DaytypeNote loadList Error: "+e);
                 });

    }

    _write_daytype_note_result(result) {
        this.loadList(); // herladen dagsoort-notities
        // m.route.set("/daytypenotelist");
    }

    write_daytype_note(formData) {
        let request_data = {
            method: "POST",
            url: Auth.getDomain()+"/api/daytypenote/",
            body: formData,
            extract: (xhr) => {return {status: xhr.status, body: xhr.responseText}}
        }
        Auth.authorize_request_data(request_data);
        m.request(request_data)
         .then(this._write_daytype_note_result)
         .catch((e) => {
                console.log("DaytypeNote write_daytype_note Error: "+e);
          })
    }
}

module.exports =  new DaytypeNote()
