// Create Update
var m = require("mithril")
var AppState = require("../services/AppState")
var Breadcrumb = require("../components/Breadcrumb")


class CaretakerChangerequestMessage {

    constructor(vnode) {
      this.changerequest_id = vnode.attrs.Id;
      const crumb = {url: "/mychangerequest/"+this.changerequest_id,
                     text: "Aanpassingsverzoek"}
      Breadcrumb.addCrumb(crumb);

      // this.view = this.view.bind(this);
    }

    view(vnode) {
        return m("Moet Nog")
    }

}

module.exports = CaretakerChangerequestMessage
