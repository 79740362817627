var m = require("mithril");
var AppState = require("../services/AppState");
var Auth = require("../services/Auth");


class ChildCrewRatio {

    constructor() {
       this.child_crew_ratio = [];
       this.loadChildCrewRatio = this.loadChildCrewRatio.bind(this);
       this.getChildCrewRatioByDaytype = this.getChildCrewRatioByDaytype.bind(this);
    }

    loadChildCrewRatio() {
        this.child_crew_ratio = [];// als het mis gaat, is het iig leeg
        let request_data = {
            method: "GET",
            url: Auth.domain+"/api/eilandday/"+AppState.get_filter_date()+"/child-crew-ratio/"
            // todo: naar onderstaande format. API moet eerst
            // url: Auth.domain+"/api/eilandday/child-crew-ratio/?date="+AppState.get_filter_date()
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then((result) => {
                    this.child_crew_ratio = result;
                    this.child_crew_ratio.ratio_data = JSON.parse(this.child_crew_ratio.ratio_data); })
                .catch((e) => {
                    console.log("Geen Kind/Ratio voor datum: "+AppState.get_filter_date());
                    // console.error("loadChildCrewRatio Error: "+e);
                })

    }

    getChildCrewRatioByDaytype(day_type_id) {
        // console.log("Zoeken Kind/Crew-Ratio Voor id: "+day_type_id);
        if (this.child_crew_ratio.ratio_data) {
            if(day_type_id==0 &&
               this.child_crew_ratio.ratio_data.day_child_crew_ratio_eiland) {
               return this.child_crew_ratio.ratio_data.day_child_crew_ratio_eiland;
            }
            else {
                let day_type_data_by_day_type = this.child_crew_ratio.ratio_data.daytype_data_by_daytype;
                // console.log("day_type_data_by_day_type: "+day_type_data_by_day_type);
                if(day_type_data_by_day_type) {
                    for(var i = 0; i < day_type_data_by_day_type.length; i++) {
                       console.log(day_type_data_by_day_type[i].day_type.id+"?=="+day_type_id);
                       if(day_type_data_by_day_type[i].day_type.id==day_type_id) {
                          return day_type_data_by_day_type[i].child_staff_ratio;
                       }
                    }
                } // else er wordt null teruggeleverd.
            }
        }
        return null; // een raise("Geen RatioData Geladen")
    }
}

module.exports = new ChildCrewRatio()
