var m = require("mithril")
// var Auth = require("../services/Auth")
// var AppState = require("../services/AppState")


class MenuSelectCount {

    constructor() {
       // this._selectCount = localStorage.getItem('menuSelectCount');
       try {
           this._selectCount = JSON.parse(localStorage.getItem('menuSelectCount'));
           if(this._selectCount==null) {
            //console.info("Nieuw Telraam")
            this._selectCount = {};
           }
       } catch (e) {
            //console.info("Nieuw Telraam")
            this._selectCount = {};
       }

       this.updateMenuSelect = this.updateMenuSelect.bind(this);
       this.getMenuSelectCount = this.getMenuSelectCount.bind(this);

    }

    updateMenuSelect(menuId) {
       try {
            //console.log("_selectCount: "+this._selectCount);
            if(this._selectCount[menuId]==null){
               //console.info("Nieuw Telraam waarde")
               //console.log("Eerte Keer "+menuId);
               this._selectCount[menuId] = 1;
            } else {
               //console.log("n-de Keer "+menuId);
               this._selectCount[menuId]++;
            };
       } catch (e) {
            console.error("Fout updaten telling ("+menuId+"): "+e);
       }
       localStorage.setItem('menuSelectCount', JSON.stringify(this._selectCount));
    }


    getMenuSelectCount() {
       return this._selectCount
    }

}

module.exports = new MenuSelectCount()
