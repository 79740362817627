var m = require("mithril")
var AppState = require("../services/AppState");
var Auth = require("../services/Auth");

class ActivityResource {

    constructor() {
       this.list =  [];
       this.count = 0;
       this.activityresource = null;
       this.your_activity_count = "-";
       this.date_filter = null;
       this.loadFromServer = this.loadFromServer.bind(this);
       this._receiveData = this._receiveData.bind(this);
       this.getActivityResource = this.getActivityResource.bind(this);
       this.getActivityResourceByCrew = this.getActivityResourceByCrew.bind(this);
       this.loadActivityResource = this.loadActivityResource.bind(this);
       this._receiveActivityResourceData = this._receiveActivityResourceData.bind(this);


       this.child_id;
       this.child_activityresource_today = [];
       this.child_activityresource_today_status = "";

       this._receiveActivityResourceSameDateForChild = this._receiveActivityResourceSameDateForChild.bind(this);
       this.loadActivityResourceSameDateForChild = this.loadActivityResourceSameDateForChild.bind(this);
       this.resetActivityResourceSameDateForChild = this.resetActivityResourceSameDateForChild.bind(this);


    }

    _receiveData(result) {
       this.count = result.count;
       this.list = result.results;
       this.date_filter = AppState.get_filter_date();
       this.your_activity_count = 0;
       this.list.forEach((activity) => {
                             if(activity.crew==Auth.crew_id){
                                this.your_activity_count++;
                             }
                         });
    }

    loadFromServer() {
        if(this.date_filter == AppState.get_filter_date()) {
            // console.log("datumfilter geen verschil");
            return
        }
        var request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/activityresource/?date="+AppState.get_filter_date()
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveData)
     }

    _receiveActivityResourceData(result) {
        this.list.push(result);
        this.activityresource = result;
    }

    getActivityResource(id) {
        if(this.activityresource && this.activityresource.id==id) {
               return this.activityresource;
        }
        for (var _index in this.list) {
            this.activityresource = this.list[_index];
            if (this.activityresource.id == id) {
                return this.activityresource; //  focus is on activityresource
            }
        }
        this.loadActivityResource(id);
        // console.log("this.activityresource: "+this.activityresource);
    }

    getActivityResourceByCrew(crew_id) {
        return this.list.map((activityresource) => {
                             if(activityresource.crew ==crew_id) {
                                  return activityresource
                             }
                             })
    }

    loadActivityResource(id) {
        var request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/activityresource/"+id+"/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveActivityResourceData)
    }


    /**
     *
     *
     **/
    _receiveActivityResourceSameDateForChild(result) {
       this.child_activityresource_today = result;
       // this.child_activityresource_today_status = "Gegevens Opgehaald";
    }

    loadActivityResourceSameDateForChild(child_id) {
        // of api/child/<child_id>/activityresource/?date=<date>
        var request_data = {
                method: "GET",
                url: Auth.getDomain()+"/api/activityresource/"+this.activityresource.id+"/get-activityresource-same-date-for-child/"+child_id+"/",
                //url: Auth.getDomain()+"/api/activityresource/"+this.activityresource.id+"/get-activityresource-same-date/"+child_id+"/",
        }
        Auth.authorize_request_data(request_data);
        this.child_activityresource_today = [];
        this.child_id = child_id;
        // this.child_activityresource_today_status = "Ophalen Gegevens";
        return m.request(request_data)
                .then(this._receiveActivityResourceSameDateForChild)

    }

    resetActivityResourceSameDateForChild() {
        this.child_activityresource_today_status = "";
        this.child_activityresource_today = [];
        this.child_id = null;
    }
}

module.exports = new ActivityResource()
