var m = require("mithril")
var EvGService = require("../services/EvGService");
var Auth = require("../services/Auth");
var AppState = require("../services/AppState");
var Breadcrumb = require("../components/Breadcrumb");

var ChildEilanddayActivity = require("../components/ChildEilanddayActivityResource");
var ChildEilanddaySchedule = require("../components/ChildEilanddaySchedule");
var ChildImgName = require("../components/ChildImgName")

var Child = require("../models/Child");
var ChildEilandday = require("../models/ChildEilandday");
var ChildNote = require("../models/ChildNote");
var ChildActivityResource = require("../models/ChildActivityResource");
var BlogMsg = require("../models/BlogMsg");


class ChildPage {

    constructor(vnode) {
        if (vnode.attrs.Id) {
        }
    }

	view(vnode  ) {
	    if(Child.child == null) {
			return('div', 'Geen kind');
		}


		return m('.block_container',
		         {style:"overflow-y: scroll; height: 100vh;"},
		         [
 		               m(ChildImgName, {child: Child.child }),
                       m('.evg_block',
                         m('.label_value',
                            m('label', "Mentor"),
                            m('.value', Child.child.mentor_full_name))
                         ),

                       m('.evg_block',
                          m('.label_value',
                              m('label', "Notities"),
                              m('.value', "#dagen geleden: "+Child.child.last_observation_days_ago)),
                         ),
                       m('.evg_block',
                            m('.label_value',
                              m('label', "Blog"),
                              m('.value',
                                 m('.value', "#dagen geleden: "+Child.child.last_blog_days_ago),
                                 m('.value', m("a.menu_choice", { href:"#!/child/"+Child.child.id+"/blogmsglist", },
                                               "Blogberichten bekijken ("+BlogMsg.child_blogmsg_count+")"),)))
                         ),

                       m('.evg_block',
                            m('.label_value',
                              m('label', "Gekaderde Activiteiten"),
                              m('.value', m(ChildEilanddayActivity,{"id":vnode.attrs.id})))
                          ),

                       m('.evg_block',
                            m('.label_value',
                              m('label', "EvG Rooster"),
                              // value is hier in een block, we hebben alle ruimte nodig
                              m('.block', m(ChildEilanddaySchedule, {"schedule_list": ChildEilandday.my_childeiland_list})))
                          ),
/*
                       m('.evg_block.list-item-actions.pull-left',
                         [ // m("a.menu_choice", { href:"#!/child/"+Child.child.id+"/activitylist", },
                           //   "Gekaderde activiteiten bekijken ("+ChildActivityResource.count+")"),
                           // m("a.menu_choice", { href:"#!/child/"+Child.child.id+"/schedule", },
                           //   "Rooster bekijken ("+ChildSchedule.count+")"),
                           m("a.menu_choice", { href:"#!/child/"+Child.child.id+"/blogmsglist", },
                             "Blogberichten bekijken ("+BlogMsg.child_blogmsg_count+")"),
                          ])
*/
		         ]
		         )
	}

}

class MyChildPage { // CaretakerChildPage

    constructor(vnode) {
       if (vnode.attrs.Id) {
           try {
               Child.getChild(vnode.attrs.Id);
               // ChildNote.loadListForChild(vnode.attrs.Id);
               ChildEilandday.loadMyChildEilanddaysForChild(vnode.attrs.Id);
               ChildActivityResource.loadChildDayActivityresourceList(vnode.attrs.Id);
               BlogMsg.loadBlogMsgListForChild(vnode.attrs.Id);
           } catch(err) {
               console.error("MyChildPage.constructor: "+err);
           }

           const crumb = {url: '/mychild/' + vnode.attrs.Id,
                          text: "Kind"}
           Breadcrumb.addCrumb(crumb);
           this.header_data = [m(Breadcrumb),
                                        m("span.filter-date", AppState.get_filter_date()),]
       } else {
           console.error("MyChildPage geen id")
       }
    }

    view(vnode) {
              console.log("vnode.attrs.Id: "+vnode.attrs.Id);

              m.render(document.getElementById("header"),
                       this.header_data);

              return m("child-page.evg_module",
                       m(ChildPage, {"id":vnode.attrs.Id})
                       )
    }
}

module.exports = MyChildPage