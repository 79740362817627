var m = require("mithril")
var AppState = require("../services/AppState")
var EvGService = require("../services/EvGService")
var Auth = require("../services/Auth")
var Breadcrumb = require("../components/Breadcrumb")
var DaytypeDropdown = require( "../components/DaytypeDropdown");
var DaytypeNote = require("../models/DaytypeNote");


class NoteListItem {

    constructor(vnode) {
        this.have_seen_note = this.have_seen_note.bind(this);
        this.renderHaveSeenNote = this.renderHaveSeenNote.bind(this);
        this.view = this.view.bind(this);
    }

    have_seen_note() {
        if(this.note.urgency > 3) {
            if( this.note.seen_once == true) {
               DaytypeNote.have_seen_note(this.note.id);
               this.note.marked_as_seen = true; // tijdelijke test
            } else {
               this.note.seen_once = true;
               m.redraw();
            }
        } else {
               DaytypeNote.have_seen_note(this.note.id);
               this.note.marked_as_seen = true; // tijdelijke test
               m.redraw();
        }
        // m.redraw();
    }

    renderHaveSeenNote() {
       if( this.note.marked_as_seen == true) return; // tijdelijke test
       if(this.note.urgency > 3 ) {
           if(this.note.seen_once == true) {
              return m(".have_seen", "TAP NOGMAALS voor aangeven Gezien");
           }
           return m(".have_seen", "TAP 2x voor aangeven Gezien");
       }
       return m(".have_seen", "TAP voor aangeven Gezien");
    }

    view(vnode) {
        this.note = vnode.attrs.note;
        let urgency_class = ".urgency_"+this.note.urgency
        let note_period = this.note.date
        if (this.note.date_to) {
            if (this.note.date == this.note.date_to) {
                note_period = note_period+".";
            } else {
                note_period = note_period+" t/m "+this.note.date_to;
            }
        } else {
            note_period = "vanaf "+ note_period; //+" t/m vandaag";
        }
        return m('li.evg-list-item'+urgency_class,
                  {onclick:this.have_seen_note},
                  [ m('.urgency', this.note.day_type_name+" "+note_period),
                    this.renderHaveSeenNote(),
                    m('urgency', this.note.content) ]
                )

     }
}


class NoteList {

    constructor(vnode) {
        // this.filter_note = thios.filter_note.bind(this)
    }

    note_filter(note) {
        if ( AppState.overrideHasSeen &&
            (AppState.daytypeFilter==note.day_type || AppState.daytypeFilter==0 )) {
            return true
        }
        if (note.marked_as_seen) {
          return false
        }
        if (AppState.daytypeFilter == 0 ||
            note.day_type == 6 ||
            note.day_type == AppState.daytypeFilter) {
           return true
        }
        return false
    }

    view(vnode) {
        let note_list = [];
        let filtered_note_list = []
        try {
            filtered_note_list = DaytypeNote.list.filter( this.note_filter );
        } catch (e) {
           console.error("Fout Filteren Daytypenotelist: "+e);
        }
        if(filtered_note_list.length==0) {
           note_list.push(m('li.evg-list-item', "Geen Notities"));
        } else {
           filtered_note_list.forEach( (f_note) => {
              note_list.push(m(NoteListItem, {'note': f_note}));
           })
           note_list.push(m('li.evg-list-item.end-of-list-item',
                             m("span", {style:'vertical-align:center'}, "Einde lijst")));

        }
        return m('ul.evg-list', note_list);
    }
}


class ConditionalDaytypeNoteCreateButton {

    view(vnode) {
         //console.log("ChildSelectNoteCreate.view() AppState.childFilter: "+AppState.daytypeFilter);
         if (AppState.daytypeFilter>0) {
           return m( "div.evg_block",
                     m("a.menu_choice",
                       { href:"#!/daytypenote/"+AppState.get_filter_date()+"/"+AppState.daytypeFilter+"/create", },
                       "Groep Notitie maken!")
                   )
         } else {
           return m( "div.evg_block", "Selecteer een Groep voor het maken van een Notitie voor die Groep.")
         }
    }
}


class DaytypeNoteList {

    constructor(vnode) {
       DaytypeNote.loadList();
       const crumb = {url:"/daytypenotelist",
                      text:"Notities/Dagsoort"};
       Breadcrumb.addCrumb(crumb);
    }

    view(vnode) {

        m.render(document.getElementById("header"),
         [m(Breadcrumb),
          m("span.filter-date", AppState.get_filter_date()),
         ]);

        return m("daytypenotelist.evg_module",
           [
            m( "div.evg_block",
               m("a.menu_choice",
                 { onclick:AppState.toggleOverrideHasSeen},
                 "Alle Bekeken Notities "+(AppState.overrideHasSeen ? "verbergen":"tonen"))),

            m( "div.evg_block",
                  [m("label", "Groep"),
                   m(".value.input",
                     m(DaytypeDropdown))
                  ]),

            m(ConditionalDaytypeNoteCreateButton),

            m("div.evg_list_container_one_filter",  // geen filter, maar bovenstaande div neem ook ruimte in
              m(NoteList)),
           ]
          )
    }
}

module.exports = DaytypeNoteList
