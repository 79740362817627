var m = require("mithril")

//var AppState = require("../services/AppState")
var EvGService = require("../services/EvGService")
var VersionCheck = require("../services/VersionCheck")
var Breadcrumb = require("../components/Breadcrumb")
var Child = require("../models/Child") // even tijdelijk voor testen mulktiselect

var MenuSelectCount = require("../models/MenuSelectCount");


class VersionPage {
    constructor(vnode) {
        const crumbs = [ {url: '/menu', text: "Menu"},
                         {url: '/version', text: "Nieuwe Versie"}
                         ]
        Breadcrumb.setCrumbs(crumbs);

        this.update_if_different = this.update_if_different.bind(this);
        this.apk_tap_count=0;
        this.tap_for_apk = this.tap_for_apk.bind(this);
        this.render_test_multiple_select = this.render_test_multiple_select.bind(this);
        this.view = this.view.bind(this);
    }

    update_if_different() {
      if(VersionCheck.version != VersionCheck.version_on_server) {
             return m("button.menu_choice",
                      {onclick: VersionCheck.activate_new_version },
                      "Klik hier om de nieuwe versie te gebuiken")
      }
    }

    tap_for_apk(){
       this.apk_tap_count += 1;
       m.redraw()
    }

    render_test_multiple_select() {
        return  m(".evg_block",
                 [m("h3", "Select Test"),
                  m("select.evg_block",
                    {id:"id_counter", name:"counter", required:"required", autofocus:"autofocus", multiple:"true"},
                     Child.on_eiland_today_list.map(function(child){
                                       console.log(" other_child_select child On eiland today: "+child)
                                       //if (Child.child.id != child.id) {
                                           return m("option",
                                                    {value:child.id},
                                                    child.first_name+" "+child.last_name)}
                                       //}
                                   )
                    )]
                  )
    }


    render_buildinfo() {
       const buildinfo = VersionCheck.get_buildinfo();
       let data = [];
       const build_msg = `Keesje@Mobiel v${buildinfo.gitHashShort} compiled at ${new Date(buildinfo.time ).toISOString()}`;
       data.push(m('.buildinfo-data', build_msg));
       for(let key in buildinfo) {
           if(buildinfo.hasOwnProperty(key)) {
              const value = buildinfo[key];
              data.push(m('.buildinfo-data', key+": "+value ));
           }
       }
       if(this.apk_tap_count>2) {
           data.push(m('.apk_download', "Ik Weet Wat Ik Doe."));
           if(this.apk_tap_count>5) {
              let download_url = "https://mobiel.keesje.eilandvangroningen.nl/keesje-mobiel-"+buildinfo['gitHashShort']+".apk";
              data.push(m('.apk_download',m('a',{href:download_url}, "Doe Mij De APK.")));
              let version_url = VersionCheck.version_array.join("-");
              //console.log("version_url: "+version_url);
              let page_update_test_url = "https://mobiel.keesje.eilandvangroningen.nl/mobiel/index-"+version_url+".html"; // ?hashTest="+buildinfo['gitHashShort'];
              data.push(m('.update_test',m('a',{href:page_update_test_url}, "Doe Mij Update Test.")));
           }
       }
       return m(".evg_block",
                 [m("h3", "Bouw Info"),
                  m('.buildinfo', {onclick:this.tap_for_apk}, data)
                  ]
                )
    }

    renderMenuSelectCount() {
         let menucountlist = MenuSelectCount.getMenuSelectCount();
         //console.log("menucountlist: "+menucountlist);
         let result = [];
         Object.keys(menucountlist).forEach(function(key, index) {
              //console.log( key+":"+menucountlist[key]);
              result.push( m("li", key+": "+menucountlist[key]) );
            });
         return  m(".evg_block",
                  [m("h3", "Klikken per Menu"),
                   m("ul", result)
                   ])

    }

    renderVersie() {
      return m(".evg_block",
                  [m("h3", "Versie"),
                   m("p", "Deze versie: "+VersionCheck.version),
                   m("p", "Op de server: "+VersionCheck.version_on_server)
                  ])
    }

    view(vnode) {
        m.render(document.getElementById("header"),
                  [ m(Breadcrumb),
                  ]);
        return m( "version.evg_module",
                 [ this.renderVersie(),
                   this.render_test_multiple_select(),
                   this.renderMenuSelectCount(),
                   this.update_if_different(),
                   this.render_buildinfo()
                 ])
    }
}

module.exports = VersionPage
